import React, { useRef, useState } from 'react'
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import emailjs from '@emailjs/browser';

function Footer() {
   const form = useRef();
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  }
  )
  const submited = (e) => {
    e.preventDefault();
    console.log(data);
    emailjs
      .sendForm(
        "service_h5v2x2k",
        "template_jap3iud",
        form.current,
        "XcjgjqEOJjAQ0fpCf"
      )
      .then(
        (result) => {
          console.log(result.text);
          setData({
            name: "",
            email: "",
            message: "",
          })
          window.alert("your mail has been sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  return (
         <div className='foot' id='contact'>
        <h1 className='py-4 fs-1 text-white m-0'><span className='highlight fw-bolder'>Get in touch </span>with us</h1>
        <div className='footer d-flex justify-content-center text-white align-items-center'>
          <footer className='row w-75 my-5'>
            <div className='col-lg-3 col-md-6 col-12 bottom-links'>
              <h2>Our Company</h2>
              <ul className='list-unstyled mt-3'>
                <li><a href='#home'>Home</a></li>
                <li className='my-1 fw-light'><a href='#about' >About us</a></li>
                <li className='my-1'><a href='#product'>Our product range</a></li>
                <li><a href='#contact'>contact us</a></li>
              </ul>
            </div>
            <div className='col-lg-3 col-md-6 col-12 address'>
              <h2>Reach Us</h2>
              <p className='my-4 lh-lg'><ImLocation2 />247 Jaswant Nagar Khatipura,<br />Jaipur-302012, Rajasthan, India</p>
              <ul className='list-unstyled d-flex flex-row text-white'>
                <li className='fs-3'><FaLinkedin /></li>
                <li className='mx-3 fs-3'><FaFacebookSquare /></li>
                <li className='fs-3'><FaTwitterSquare /></li>
              </ul>
            </div>
            <div className='col-lg-6 col-12 mail'>
              <h2>Leave a Message,we will call you back!</h2>
              <form onSubmit={(e) => submited(e)} className='needs-validation' ref={form}>
                <input type='name' value={data.name} className='form-control p-2 mt-3' name='user_name' placeholder='Name' onChange={(e) => setData({ ...data, name: e.target.value })} required />
                <input type='text' value={data.email} className='form-control p-2 my-3' name='user_email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder='Email' onChange={(e) => setData({ ...data, email: e.target.value })} required />
                <textarea type='text' value={data.message} name='message' rows={6} className='form-control p-2 mb-3' onChange={(e) => setData({ ...data, message: e.target.value })} placeholder='Enter Here' required />
                <button type='submit' className='btn-outline-primary btn send'>Send</button>
              </form>
            </div>
          </footer>
        </div>
      </div>
  )
}

export default Footer