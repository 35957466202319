import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import new1 from './svgs/new1.svg';
import new2 from './svgs/new2.svg';
import new3 from './svgs/new3.svg';
function Productcard() {
  return (
    <Carousel variant="light">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={new1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>Furniture</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={new2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Spices</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={new1}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Coal & Cokes</h5>
          <p>Indonesian Steam Coal is soft in texture, black & lightweight and is known for its purity,<br/>low ash, Various sizes & GAR (Gross As Received) and high thermal efficiency</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={new3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Fruits & Veggies</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Productcard