export const links = [
  {
    id: 0,
    name: "Coal & Cokes",
    desc: "it's of grade 5000, 5200, 6500, 6200 and it's hs code is 27011920 and its origin is Indonesia",
    url: "https://images.unsplash.com/photo-1517999144091-3d9dca6d1e43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=327&q=80",
  },
  {
    id: 1,
    name: "Cashew Nuts",
    desc: "All variety of different countries are available . It's hs code is 08013100",
    url: "https://images.unsplash.com/photo-1509912760195-4f6cfd8cce2c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    id: 2,
    name: "Rice",
    desc: "It's grade is 1121, CELLA ,etc. it's origin is India with all types of variety. It's hs code is 1006",
    url: "https://images.unsplash.com/photo-1586201375761-83865001e31c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    id: 3,
    name: "Spices",
    desc: "All type of varieties are present in it . it's origin is India and it's hs code is 07149090",
    url: "https://images.unsplash.com/photo-1596040033229-a9821ebd058d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    id: 4,
    name: "Pulses",
    desc: "Having all types of varieties in it coming from Indian origin it's hs code is 0713",
    url: "https://images.unsplash.com/photo-1635843095815-7c85a923e734?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    id: 5,
    name: "Paper Products",
    desc: "Handmand craft with indian origin and shipped over more than 15 countries till now",
    url: "https://img.freepik.com/free-psd/take-away-paper-cup-with-straw-mockup_439185-2287.jpg?w=900&t=st=1666165181~exp=1666165781~hmac=1deaf47dafb9075f75ed0eaffa5fcf68df3bc0a52b6db844acef38792db31420",
  },
  {
    id: 6,
    name: "Cement",
    desc: "Having Indian origin with an A+ quality it's hs code is 84178010",
    url: "https://images.unsplash.com/photo-1603777953081-624bff44a0a5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    id: 7,
    name: "Wheat",
    desc: "Having an Indian origin its grades are A, B, C, D  and it's hs code is 110100",
    url: "https://images.unsplash.com/photo-1501430654243-c934cec2e1c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    id: 8,
    name: "Sugar",
    desc: "With the Indian origin and an A+ quality . It's grades are 530, M30, 531, M31. It's hs code is 17019100",
    url: "https://images.unsplash.com/photo-1610219171189-286769cc9b20?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
  },
  {
    id: 9,
    name: "Iron Ore",
    desc: "With an Indian origin and grade of 60+ its varities are Magenetite and Hematite and it's hs code is 26011150",
    url: "https://images.unsplash.com/photo-1596366799486-301666a0477e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1075&q=80",
  },
  {
    id: 10,
    name: "River Sand",
    desc: "Having it's origin from India and having a high grade quality it's hs code is 25309099",
    url: "https://images.unsplash.com/photo-1600007098210-74a71aac22a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=911&q=80",
  },
];
