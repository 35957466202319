import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import { BiArrowBack } from "react-icons/bi";
import "./productimg.css";
import { links } from "./data";
import Header from "./Header";

function Productpage() {
  return (
    <>
      <div className="header">
        <Header />
      </div>
      <div className="text-center fs-1 producttitle fw-normal">Products</div>
      <div className="container-fluid my-4 products">
        <div className="row">
          <div className="card-columns">
            {links.map((ele, index) => {
              return (
                <div className="card" key={index}>
                  <NavLink to={`/product/${ele.id}`}>
                    <div className="overlay justify-content-center align-items-center">
                      <h3 className="text-white">{ele.name}</h3>
                    </div>
                    <div className="card-body p-0">
                      <img
                        className="card-img-top cardimage"
                        src={ele.url}
                        alt="jjp"
                      />
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Productpage;
