import React from "react";
import { NavLink, useParams } from "react-router-dom";
import Footer from "./Footer";
import { BiArrowBack } from "react-icons/bi";
import "./productimg.css";
import { links } from "./data";
import { useEffect } from "react";
import { useState } from "react";
function Singleitem() {
  const { id } = useParams();
  const [single, setSingle] = useState("");
  const [singlename, setSinglename] = useState("");
  const [singledesc, setSingledesc] = useState("");
  const getelement = (ide) => {
    const temp = links.filter((item) => {
      return item.id === Number(ide);
    });
    setSingle(temp[0].url);
    setSinglename(temp[0].name);
    setSingledesc(temp[0].desc);
  };
  useEffect(() => {
    getelement(id);
  }, []);
  return (
    <>
      <div className="back row justify-content-end m-3">
        <NavLink to="/products" className="btn p-2 rounded">
          <BiArrowBack />
          Back
        </NavLink>
      </div>
      <div className="text-center fs-1 fw-normal">Product</div>
      <div className="my-5 container-fluid row justify-content-center">
        <div className="w-75 row">
          <div className="col-md-6 col-12">
            <div className="card">
              <div className="card-body p-0">
                <img
                  className="card-img-top cardimage"
                  src={single}
                  alt="jjp"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="px-md-5 py-4 py-md-0">
              <h1>{singlename}</h1>
              <p style={{fontSize:"1.3rem"}}>{singledesc}</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Singleitem;
