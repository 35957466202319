import React from 'react';
import error from './svgs/error.svg';
function Error() {
  return (
    <div className='justify-content-center row align-items-center err'>
        <img src={error} alt="error" />
        </div>
  )
}

export default Error