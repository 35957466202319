import React from "react";
import Landing from "./Landing";
import { Routes, Route } from "react-router-dom";
import Productpage from "./Productpage";
import Singleitem from "./Singleitem";
import Error from "./Error";
function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/products" element={<Productpage />} />
        <Route path="/product/:id" element={<Singleitem />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
