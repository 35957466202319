import React from "react";
import logo from "./images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="d-flex justify-content-center ">
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="white"
          variant="white"
          className="shadow"
        >
          <Container>
            <Navbar.Brand href="#home">
              <img src={logo} alt="logo" id="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLink to="/" className="text-dark home text-decoration-none">
                  <Nav.Link href="#ho" className="text-dark position-relative">
                    Home
                  </Nav.Link>
                </NavLink>
                <Nav.Link
                  href="#about"
                  className="text-dark position-relative mx-lg-4"
                >
                  About Us
                </Nav.Link>
                <NavLink to="/products" className="text-dark product">
                  <Nav.Link
                    href="#pro"
                    className="text-dark position-relative "
                  >
                    Products
                  </Nav.Link>
                </NavLink>
                <Nav.Link
                  href="#contact"
                  className="text-dark position-relative mx-lg-4"
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
