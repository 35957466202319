import React from "react";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import card from "./images/card.png";
import parlsvg from "./svgs/parlsvg.svg";
import Productcard from "./Productcard";
import Header from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
function Landing() {
  const { ref: myRef, inView: visible } = useInView();

  const changedata = () => {
    let contract = document.querySelectorAll(".counter-number");
    contract.forEach((contra) => {
      let target = Number(contra.getAttribute("data-target"));
      if (contra.innerHTML === "0") {
        let starting = +contra.innerHTML;
        let inc = target / 100;
        if (starting !== target) {
          let timer = setInterval(() => {
            starting += inc;
            contra.innerHTML = starting;
            if (starting === target) {
              contra.innerHTML += "+";
              clearInterval(timer);
            }
          }, 15);
        }
      }
    });
  };
  useEffect(() => {
    if (visible) {
      changedata();
    }
  }, [visible]);
  return (
    <>
      <div className="header">
        <Header />
      </div>
      <div className="hero" id="home">
        <div className="onlyphone text-white">
          <h2 className="mb-5">
            Welcome to
            <br />
            Modish Group
          </h2>
          <p>
            Modish Group is a reputed and
            <br />
            well-established firm and is in
            <br />
            the wholesaling business since
            <br /> 2011 and has been making positive
            <br /> impact since then.
          </p>
        </div>
        <button className=" btn btn-light explore rounded-5 px-3 explore">
          <NavLink className="text-decoration-none" to="/products">
            Explore More
          </NavLink>
        </button>
      </div>
      {/* <div className="parallax">
      </div> */}
      <div
        className="parallax-window"
        data-parallax="scroll"
        data-image-src={parlsvg}
      ></div>
      <div className="d-flex flex-row justify-content-center about">
        <div
          className="d-flex flex-column justify-content-center align-items-start my-5 w-75"
          id="about"
        >
          <h2 className="mb-3 fw-bold abouthead">
            About<span className="fw-normal mx-2">Us</span>
          </h2>
          <p className="abouttext">
            Established as a Proprietor firm in the year 2012 at Jaipur
            (Rajasthan, India), we “Modish
            <br />
            Group” are a leading Wholesale Trader of a wide range of Coal,
            Aluminum, Copper, Iron
            <br />
            (Indonesian), Electronics, Clothes, Shoes, Furniture, Cycles ,
            Cereals. We procure these
            <br />
            products from the most trusted and renowned vendors after stringent
            market analysis.
            <br />
            Further, we offer these products at reasonable rates and deliver
            these within the
            <br />
            promised time-frame. Under the headship of “Mr. Virendra Rathore”
            (Owner), we have
            <br />
            gained a huge clientele across the nation
          </p>
        </div>
      </div>
      <div className="products my-2" id="product">
        <Productcard />
      </div>
      <div className="carding">
        <img src={card} alt="card" />
        <div className="row changing justify-content-center" ref={myRef}>
          <div
            className="col-3 text-white row justify-content-center counter-number"
            data-target="150"
          >
            0
          </div>
          <div
            className="col-3 text-white row justify-content-center counter-number"
            data-target="2050"
          >
            0
          </div>
          <div
            className="col-4 text-white row justify-content-center counter-number"
            data-target="150"
          >
            0
          </div>
        </div>
        <div className="row  changing changetext justify-content-center">
          <div className="col-3  text-white row justify-content-center">
            Clients
          </div>
          <div className="col-3  text-white row justify-content-center">
            Sells
          </div>
          <div className="col-4  text-white row justify-content-center">
            Countries we served
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Landing;
